import React from 'react';
import MediaDetailPanel from './MediaDetailPanel';
import SidePanel from './SidePanel';
import Filters from './Filters';
import Legend from './Legend';
import TimelineElement from './TimelineElement';
import EraIcon from './EraIcon';


class Timeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortField: 'sort_order'
    };

    this.setSortField = this.setSortField.bind(this);
    this.clearSelectedMediaId = this.clearSelectedMediaId.bind(this);
  }

  clearSelectedMediaId() {
    this.setState({
      'selectedMediaId': null,
      'selectedMediaIdList': null,
    });
  }

  setSelectedMediaId(selectedMediaId) {
    // set selectedMediaId to the media id of the currently selected row,
    // and selectedData to any array of the selected media id plus any
    // other media ids from the same collection if the user is grouping by collection
    const data = this.props.data;
    const selectedRow = data.find(x => x.media_id === selectedMediaId);
    const selectedData = data.filter(x =>
      (((this.props.aggregateCollection && x.format === 'C')
        || (this.props.aggregateSeason && x.format === 'T'))
        && x.collection_title
        && x.collection_title === selectedRow?.collection_title)
      || x.media_id === selectedMediaId
    );
    const selectedMediaIdList = selectedData.map(x => x.media_id);
    this.setState({
      selectedMediaId,
      selectedMediaIdList,
    });
  }

  setSortField = (field) => {
    const sort_fields = ['release_date', 'sort_order', 'avg_rating'];

    if (sort_fields.includes(field) && document.getElementById(field).checked) {
      this.setState({ sortField: field });
    }
  }

  render() {
    let data = this.props.data;
    const selectedRow = data.find(x => x.media_id === this.state.selectedMediaId);
    let searchText = false;

    if (this.props.showSidePanel !== false && document.getElementById('search-text')?.value) {
      searchText = document.getElementById('search-text').value;
    }

    // filter based on title, if the user is searching by title
    // apply search text here instead of App.js
    // because we don't want it to apply to any other pages
    if (searchText && searchText !== '') {
      data = data.filter(x => x.title.toLowerCase().includes(searchText.toLowerCase()));
    }

    if (this.state.sortField === 'release_date') {
      data.sort((a, b) => new Date(b['release_date'] || '2099-01-01') - new Date(a['release_date'] || '2099-01-01'));
    } else if (this.state.sortField === 'sort_order') {
      data.sort((a, b) => a[this.state.sortField] - b[this.state.sortField]);
    } else {
      data.sort((a, b) => (b[this.state.sortField] || 1) - (a[this.state.sortField] || 1));
    }

    return (
      <div>
        <MediaDetailPanel
          data={selectedRow}
          addRating={this.props.addRating}
          loggedIn={this.props.loggedIn}
          onClose={this.clearSelectedMediaId}
          setView={this.props.setView}
        >
        </MediaDetailPanel>

        {
          this.props.showSidePanel !== false
            ?
            <div className='side-panel-container'>
              <SidePanel
                title='Options & Filters'
                content={<Filters
                  filters={this.props.filters}
                  addFilter={this.props.addFilter}
                  searchText={searchText}
                  setSortField={this.setSortField}
                  sortField={this.state.sortField}
                />}
                enabled={this.props.showFilter}
                onRequestClose={this.props.toggleShowFilter}
              />
              <SidePanel
                title='Legend'
                content={<Legend />}
                enabled={this.props.showLegend}
                onRequestClose={this.props.toggleShowLegend}
              />
            </div>
            : <div />
        }

        <div className="App-timeline-rows">
          {
            data.map((x, i) => {
              return (
                <div className="timeline-row" key={`timeline-row-${x.media_id}`}>
                  {
                    (i === 0 || x.era !== data[i - 1].era) 
                    && x?.era 
                    && this.state?.sortField === 'sort_order' 
                    && this.props.showSidePanel !== false
                      ? <EraIcon data={x} />
                      : <div />
                  }
                  <TimelineElement
                    data={x}
                    onClick={() => this.setSelectedMediaId(x.media_id)}
                    aggregate={
                      (this.props.aggregateCollection && x.format === 'C')
                      || (this.props.aggregateSeason && x.format === 'T')
                    }
                    selected={x.media_id === this.state?.selectedMediaId}
                  >
                  </TimelineElement>
                </div>
              )
            })
          }
        </div>
      </div>
    );
  }
}

export default Timeline;
