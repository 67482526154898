import React from 'react';
import { openUrl, eventGuideData, convertDataToUrl, convertTitleToUrl, URL } from './Common';
import RatingsSelect from './RatingsSelect';
import { NavLink } from "react-router-dom";
import EraIcon from './EraIcon';

const COMMENT_LIMIT = 2000;

class MediaPage extends React.Component {
  constructor(props) {
    super(props);
    this.submitComment = this.submitComment.bind(this);
  }

  componentDidMount() {
    this.setState({ data: null });
  }

  componentDidUpdate() {
    const view = this.props.view?.replaceAll('content/', '');

    if (!this.props.data || !view) {
      return null;
    }

    const rawData = this.props.data.sort((a, b) => a?.sort_order - b?.sort_order);
    let dataIndex = rawData.findIndex(x => convertDataToUrl(x) === view.toLowerCase());
    if (dataIndex < 0) {
      // if the click on the season of a show, return the first episode
      dataIndex = rawData.findIndex(x => x.format === 'T' && convertTitleToUrl(x.collection_title) === view.toLowerCase());
      if (dataIndex < 0) {
        // check if the show was referencing by episode number instead of by title
        dataIndex = rawData.findIndex(x => x.format === 'T' && convertTitleToUrl(`${x.title.split(':')?.[0]}: ${x.subtitle}`) === view.toLowerCase());
      }
    }

    if (dataIndex < 0) {
      return;
    }

    if (this.state?.data && rawData[dataIndex] === this.state.data) {
      return;
    }

    const data = rawData[dataIndex];
    const prevData = rawData[dataIndex - 1];
    const nextData = rawData[dataIndex + 1];
    const oldMediaId = this.state?.data?.media_id

    this.setState({
      data,
      prevData,
      nextData,
      view: this.props.view,
    });

    if (oldMediaId !== data.media_id) {
      this.loadComments(data.media_id);
    }
  }

  loadComments(mediaId) {
    if (!mediaId) {
      return;
    }

    fetch(`${URL}/get_comments`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: "include",
      body: JSON.stringify({
        mediaId: mediaId,
      })
    })
      .then(res => res.text())
      .then(res => {
        const comments = JSON.parse(res);
        this.setState({
          comments: comments,
        });
      })
      .catch(err => err);
  }

  submitComment() {
    const mediaId = this.state?.data?.media_id;
    if (!mediaId) {
      return;
    }

    const comment = document.getElementById(`comment-box-${this.state?.data?.media_id}`)?.value;
    if (!comment) {
      return;
    }

    if (comment.length >= COMMENT_LIMIT) {
      this.setState({ commentErr: `Comment length of ${comment.length} exceeds limit of ${COMMENT_LIMIT} characters!` })
      return;
    }

    this.setState({
      commentErr: null,
    });

    fetch(`${URL}/add_comment`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: "include",
      body: JSON.stringify({
        mediaId: mediaId,
        comment: comment,
      })
    })
      .then(res => res.text())
      .then(res => {
        const data = JSON.parse(res);
        console.log(data);
        if (data?.error) {
          this.setState({
            commentErr: data?.error,
          });
        }
        else if (data?.comment_id) {
          const newComments = this.state.comments;
          newComments.push(Object.assign(data, { 'username': this.props.username }));
          document.getElementById(`comment-box-${this.state?.data?.media_id}`).value = "";
          this.setState({ comments: newComments });
        }
      })
      .catch(err => err);
  }

  render() {
    if (!this.state?.data) {
      return null;
    }

    const { setView } = this.props;
    const data = this.state?.data;
    const prevData = this.state?.prevData;
    const nextData = this.state?.nextData;

    let eventGuidePath;
    Object.entries(eventGuideData).map(([path, guideData]) => {
      if (this.props.data?.collection_title?.includes(guideData.title)) {
        eventGuidePath = path;
      }
    });

    return (
      <div className="blog-inner">
        <div className="infopanel-inner header-box">
          <div>
            <div className="title">{data?.title}</div>
            <div>{data?.subtitle}</div>
            <RatingsSelect
              data={data}
              loggedIn={this.props.loggedIn}
              addRating={this.props.addRating}
            >
            </RatingsSelect>
            <div>
              {
                data?.release_date
                  ? `Release date: ${new Date(data?.release_date).toISOString().slice(0, 10)}`
                  : 'Not yet released'
              }
            </div>
          </div>
          <EraIcon data={data} />
        </div>
        <div className="infopanel-inner blog-body">
          <div className="image">
            <img
              src={`/img/${data?.img || 'blank'}.png`}
              style={{ height: '100%' }}
              alt={`Cover art of ${data?.title}`}
            />
            {
              data?.amazon
                ? <button
                  onClick={() => openUrl(data.amazon)}
                >View on Amazon</button>
                : <div />
            }
          </div>
          <div>
            <div className="title">Plot Summary</div>
            <p>{data?.summary || 'Plot summary coming soon...'}</p>
            <p>{data?.collected_issues}</p>
            {
              data?.notes
                ? <div><div className="title">Timeline Placement Notes</div><p>{data?.notes}</p></div>
                : <div />
            }
            {
              eventGuidePath
                ? <NavLink
                  onClick={() => this.props.setView(eventGuidePath)}
                  to={`/${eventGuidePath}`}
                >
                  <button>View Event Guide</button>
                </NavLink>
                : <div />
            }
          </div>
        </div>
        <div className="infopanel-inner blog-body header-box">
          {
            prevData
              ?
              <NavLink
                className="content-suggestion"
                onClick={() => this.props.setView(`content/${convertDataToUrl(prevData)}`)}
                to={`/content/${convertDataToUrl(prevData)}`}
              >
                <div style={{ display: 'flex', width: '300px', padding: '10px', textAlign: 'start', justifyContent: 'start' }}>
                  <img
                    src={`/img/${prevData?.img || 'blank'}.png`}
                    style={{ height: '100px', margin: '10px' }}
                    alt={`Cover art of ${data?.title}`}
                  />
                  <div>
                    <div className="title">Previous in timeline:</div>
                    <p>{`${prevData.title}`}</p>
                  </div>
                </div>

              </NavLink>
              : <p />
          }
          {
            nextData
              ?
              <NavLink
                className="content-suggestion"
                onClick={() => this.props.setView(`content/${convertDataToUrl(nextData)}`)}
                to={`/content/${convertDataToUrl(nextData)}`}
                style={{ justifyContent: 'end' }}
              >
                <div style={{ display: 'flex', width: '300px', padding: '10px', textAlign: 'end', justifyContent: 'end' }}>
                  <div>
                    <div className="title">Next in timeline:</div>
                    <p>{`${nextData.title}`}</p>
                  </div>
                  <img
                    src={`/img/${nextData?.img || 'blank'}.png`}
                    style={{ height: '100px', margin: '10px' }}
                    alt={`Cover art of ${data?.title}`}
                  />
                </div>
              </NavLink>
              : <p />
          }
        </div>
        <div className="infopanel-inner blog-body" style={{ flexDirection: 'column' }}>
          <div className="title comment-text">Comments</div>
          {
            this.state.comments?.length > 0
              ? this.state.comments.map((x, i) => {
                return (
                  <div className='comment' key={`comment-${data.media_id}-${i}`}>
                    <div className='header-box'>
                      <NavLink
                        className='link-text'
                        onClick={() => setView(`/profile/${x.username}`)}
                        to={`/profile/${x.username}`}
                        style={{ paddingLeft: '2px' }}
                      >
                        {`${x.username}`}
                      </NavLink>
                      <div style={{fontSize: 'small', display: 'inline' }}>
                        {`${new Date(x.comment_date).toLocaleString()}`}
                      </div>
                    </div>
                    {
                      x.comment.split('\n').map((line, j) => {
                        return (
                          <div className='comment-text' key={`comment-${data.media_id}-${i}-${j}`}>{line}</div>
                        )
                      })
                    }
                  </div>
                )
              })
              : this.props?.loggedIn ? <div className='comment-text'>Be the first to add a comment!</div> : <div />
          }
          {
            this.props?.loggedIn
              ? <div>
                <textarea className="commentInput" id={`comment-box-${data.media_id}`} />
                {
                  this.state?.commentErr
                    ? <p>ERROR: {this.state?.commentErr}</p>
                    : <div />
                }
                <button style={{ display: 'block' }} onClick={this.submitComment}>Add Comment</button>
              </div>
              : <div className='comment-text'>Log in to your account to add comments!</div>
          }
        </div>
      </div>
    );
  }
}

export default MediaPage;
