import React from 'react';
import './App.css';
import BlogEntry from './BlogEntry';
import { convertDataToUrl } from './Common';

class Blog extends React.Component {
  componentDidUpdate() {
    if (this.props.selectedBlogEntry) {
      const selectedEntry = this.props.blogEntries?.find(x => convertDataToUrl(x) === this.props.selectedBlogEntry);
      if (!selectedEntry) {
        return;
      }
      const selectedData = this.props.data.find(x => x.media_id === selectedEntry.media_id);
      if (!selectedData) {
        return;
      }

      // set ld+json tags, cannot be done in componentDidMount because props are null
      const structuredDataText = [{
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        "headline": selectedEntry.title,
        "image": [
          `https://swtimeline.net/img/${selectedData.img}.png`
        ],
        "datePublished": selectedEntry.review_date,
        "dateModified": selectedEntry.review_date,
        "author": [{
          "@type": "Person",
          "name": "Jake Loftus",
          "url": "https://swtimeline.net"
        }],
      },{
        "@context": "https://schema.org/",
        "@type": "Book",
        "name": selectedData.title,
        "datePublished": selectedData.release_date,
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": parseFloat(selectedData.avg_rating).toFixed(1),
          "bestRating": "10",
          "ratingCount": selectedData.rating_count,
        }
      }];
      const script = document.getElementById('custom-ld-json');
      script.textContent = JSON.stringify(structuredDataText);
    }
  }

  render() {
    const { selectedBlogEntry, data } = this.props;
    const blogEntries = selectedBlogEntry ? this.props?.blogEntries?.filter(x => convertDataToUrl(x) === selectedBlogEntry) : this.props?.blogEntries;
    return (
        <div className="blog-inner">
          {
            blogEntries?.sort((a, b) => b.review_date - a.review_date)?.map(x => {
                  return (
                    <BlogEntry
                      data={data.find((media) => media.media_id === x.media_id)}
                      blogData={x}
                      key={`blog-entry-${x.media_id}`}
                      loggedIn={this.props.loggedIn}
                      addRating={this.props.addRating}
                    ></BlogEntry>
                  )
                })
          }
        </div>
    );
  }
}

export default Blog;
