import React from 'react';
import './App.css';
import { convertDataToUrl, eventGuideData } from './Common';
import { NavLink } from "react-router-dom";
import Icon from '@mdi/react';
import {
  mdiBookOpenVariant,
  mdiPostOutline,
  mdiFire,
} from '@mdi/js';


class SideNavBar extends React.Component {
  render() {
    const { blogEntries, setView, data } = this.props;
    return (
      <div className="side-nav">
        <div className="infopanel" style={{ position: 'fixed', height: 'unset' }}>
          <div className="infopanel-inner">
            <h1 className="subtitle profile-icons">
              <Icon className='icon' path={mdiBookOpenVariant} />
              Reading Guides
            </h1>
            <NavLink
              className='link-text'
              onClick={() => setView('/startingpoints')}
              to={'/startingpoints'}
              style={{ display: 'block' }}
            >
              Where to Start Reading
            </NavLink>
            <NavLink
              className='link-text'
              onClick={() => setView('/omnibus_guide')}
              to={'/omnibus_guide'}
              style={{ display: 'block' }}
            >
              Marvel Omnibus Guide
            </NavLink>
            {
              Object.entries(eventGuideData).map(([path, guideData]) => {
                return (
                  <NavLink
                    className='link-text'
                    onClick={() => setView(`/${path}`)}
                    to={`/${path}`}
                    style={{ display: 'block' }}
                    key={`side-nav-${guideData.title}`}
                  >
                    {`${guideData.title} Reading Order`}
                  </NavLink>
                )
              })
            }
          </div>
          <div className="infopanel-inner">
            <h1 className="subtitle profile-icons">
              <Icon className='icon' path={mdiPostOutline} />
              Recent Blog Posts
            </h1>
            {
              blogEntries?.slice(0, 5)?.map(x => {
                return (
                  <NavLink
                    className='link-text'
                    onClick={() => setView(`blog/${convertDataToUrl(x)}`)}
                    to={`/blog/${convertDataToUrl(x)}`}
                    style={{ display: 'block' }}
                    key={`side-nav-${x.title}`}
                  >
                    {`${x.title}`}
                  </NavLink>
                )
              })
            }
          </div>
          <div className="infopanel-inner">
            <h1 className="subtitle profile-icons">
              <Icon className='icon' path={mdiFire} />
              Popular Content Last 30 Days
            </h1>
            {
              data?.filter(x => x.popular)?.sort((a, b) => new Date(b.release_date) - new Date(a.release_date)).map(x => {
                return (
                  <NavLink
                    className='link-text'
                    onClick={() => setView(`content/${convertDataToUrl(x)}`)}
                    to={`/content/${convertDataToUrl(x)}`}
                    style={{ display: 'block' }}
                    key={`side-nav-${x.title}`}
                  >
                    {`${x.title}`}
                  </NavLink>
                )
              })
            }
          </div>
        </div>
      </div>
    );
  }
}

export default SideNavBar;
